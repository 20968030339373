import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
  state: {
    identifiers: [],
    overall: 'classic',
    spot: 'thematic',
    spotURL: 'https://vimeo.com/683024311/4f1ba33e0f',
    banner: '',
    brand: '',
    bannerImg: ''
  },
  getters: {
    getChoice: (state) => (choice) => {
      return state[choice];
    },
    getConclusion: (state) => {
      return {
        ...state.identifiers,
        banner: state.banner,
        spot: state.spot,
        overall: state.overall
      }
    }
  },
  mutations: {
    updateIdentifier(state, identifier) {
      let identifiers = state.identifiers
      const chosen = state.identifiers.includes(identifier);
      if (chosen) {
        var index = identifiers.indexOf(identifier);
        identifiers.splice(index, 1);
      } else if (state.identifiers.length === 2) {
        identifiers.shift();
        identifiers.push(identifier);
      } else {
        identifiers.push(identifier);
      }
    },
    updateChoice(state, choice) {
      if (state[choice.type] === choice.option) {
        state[choice.type] = '';
      } else {
      state[choice.type] = choice.option;
      }
    },
    updateURLChoice(state, choice) {
      if (state[choice.type] === choice.url) {
        state[choice.type] = '';
      } else {
      state[choice.type] = choice.url;
      }
    },
    defaultState(state) {
      Object.assign(state,
      {
        identifiers: [],
        overall: 'classic',
        spot: 'thematic',
        spotURL: 'https://vimeo.com/683024311/4f1ba33e0f',
        banner: '',
        brand: '',
        bannerImg: ''
      })
    },
    chooseBrand(state, brand) {
      state.brand = brand;
    }
  },
  actions: {
    // refactor later
    updateIdentifier({ commit }, payload) {
      commit('updateIdentifier', payload);
    },
    updateChoice({ commit }, payload) {
      commit('updateChoice', payload);
    },
    updateURLChoice({ commit }, payload) {
      commit('updateURLChoice', payload);
    },
    defaultState({ commit }) {
      commit('defaultState');
    },
    chooseBrand({ commit }, payload) {
      commit('chooseBrand', payload);
    },
  }
})